import { useEffect } from "react";
import "../Fraude/fraude.css";
import ProtextionText from "./ProtextionText.jsx"
import { isMobileOnly } from 'react-device-detect'

const DataProtection = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <> 
    <div className="fraud-background">
      <div className="digital-content">
        <div className={isMobileOnly? "protection-mobile-box" :"digital-box"}>
          <div className="fraud-title">
            <h5 className={isMobileOnly? "protection-title-mobile" : "digital-title"}>
            Política de Protección de Datos Personales
            </h5>
          </div>
 
        <ProtextionText />
</div>
</div>
</div>


</>
  )
}

export default DataProtection