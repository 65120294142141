import React from "react";
import { Link } from "react-router-dom";
import PressCardMobile from "./PressCardMobile";
import "./prensaMobile.css";
const PrensaMobile = ({ lastNote, rest }) => {
  return (
    <div className="press-background-mobile">
      <div className="mobile-content">
        <div className="press-box-mobile">
          <div className="head-note-mobile">
            <h5>
              {lastNote.media}
            </h5>
            <h5 className="head-date">
              {lastNote.publicationDate}
            </h5>
          </div>
          <h2 className="title-press-mobile">
            {lastNote.title}
          </h2>
          <div className="main-note-img">
            <img src={lastNote.img} alt={lastNote.alt} />
          </div>
          <div className="box-text-mobile">
            {lastNote.text.substring(0, 340)} ...
          </div>
          <div>
            <Link to={`/prensa-detail/${lastNote.id}`} className="more-mobile">
              <div className="see-mobile">Ver más</div>
            </Link>
          </div>

          <div className="notes-mobile">
            <div className="menu-note">
              <h5>ULTIMAS NOTICIAS</h5>
            </div>
            <div className="card-press-mobile">
              {rest.map((item, key) =>
                <PressCardMobile key={item.id} prensaData={item} id={item.id} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="push" />
    </div>
  );
};

export default PrensaMobile;
