import "./dataProtection.css"

const ProtextionText = () => {
  return (
    <div className='protection-data-box'>
    
    <p className='protection-text-one'>Afianzadora Latinoamericana Cia de Seguros S.A. reconoce la importancia de proteger
la privacidad y la seguridad de los datos personales de sus clientes y se compromete a
cumplir con las disposiciones de la legislación argentina en materia de protección de
datos personales, especialmente la Ley de Protección de Datos Personales (Ley
25.326) y su reglamentación.</p>


  <p className='protection-sub-sub'>Identificación de la Compañía</p>
  <p className='protection-text-afz'>Afianzadora Latinoamericana Cia de Seguros S.A. <br />
Domicilio Legal: Tucumán 117, 3º Piso, CABA <br />
Teléfono: 3986-2800<br />
Correo Electrónico: info@afianzadora.com.ar</p>
 

  <p className='protection-sub-sub'>Tipo de Datos Recopilados</p>
  <p className='protection-text'>Recopilamos datos personales de nuestros clientes, los cuales pueden incluir, entre
otros, nombres, direcciones, números de teléfono, direcciones de correo electrónico,
números de identificación, información financiera y datos relacionados con pólizas de
seguros.</p>


  <p className='protection-sub-sub'>Finalidad de la Recopilación</p>
  <p className='protection-text'>Los datos personales de nuestros clientes son recopilados con el fin de gestionar y
administrar pólizas de seguros, evaluar riesgos, procesar reclamos, brindar atención al
cliente, cumplir con obligaciones legales y mejorar nuestros servicios.</p>


  <p className='protection-sub-sub'>Base Legal para el Tratamiento de Datos</p>
  <p className='protection-text'>El tratamiento de datos personales se realiza en cumplimiento de obligaciones
contractuales, el consentimiento del titular de los datos, el cumplimiento de
obligaciones legales y el interés legítimo de la compañía en la gestión de pólizas de
seguros.</p>


  <p className='protection-sub-sub'>Derechos de los Titulares de Datos</p>
  <p className='protection-text'>Los clientes tienen derecho a acceder, rectificar, cancelar y oponerse al tratamiento de
sus datos personales, así como a revocar su consentimiento en cualquier momento.
Para ejercer estos derechos, pueden comunicarse con Afianzadora Latinoamericana
Cia de Seguros S.A. a través del email info@afianzadora.com.ar</p>


  <p className='protection-sub-sub'>Medidas de Seguridad</p>
  <p className='protection-text'>Implementamos medidas tecnológicas, administrativas y organizativas para proteger
los datos personales de accesos no autorizados, pérdidas, alteraciones o
divulgaciones indebidas, garantizando su confidencialidad, integridad y disponibilidad.</p>
 

  <p className='protection-sub-sub'>Transferencias Internacionales de Datos</p>
  <p className='protection-text'>En caso de realizar transferencias internacionales de datos personales, nos
comprometemos a garantizar que se cumplan con los requisitos legales aplicables
para garantizar un nivel adecuado de protección de datos.</p>


  <p className='protection-sub-sub'>Conservación de Datos</p>
  <p className='protection-text'>Los datos personales serán conservados durante el tiempo necesario para cumplir con
las finalidades para las cuales fueron recopilados, así como para cumplir con
obligaciones legales y resolver disputas.</p>


<p className='protection-sub-sub'>Modificaciones en la Política de Protección de Datos</p>
  <p className='protection-text'>Nos reservamos el derecho de realizar modificaciones en esta política de protección
de datos. Cualquier cambio será notificado a los clientes de manera oportuna y entrará
en vigor en la fecha indicada en la notificación.
<br />Al utilizar nuestros servicios, los clientes aceptan y consienten el tratamiento de sus
datos personales de acuerdo con esta política de protección de datos. Para cualquier
consulta o solicitud relacionada con la protección de datos personales, los clientes
pueden comunicarse con nosotros utilizando los datos de contacto proporcionados.</p>


</div>
  )
}

export default ProtextionText