import React from 'react'
import { Icon } from '@iconify/react'

const PolizaDigitalLinks = ({caracter, title, link, linkName}) => {
  return (
    <div><div className='digital-item'>
    <div className='caracter'>{caracter}</div>
    <div className='digital-grey'><p>{title}</p></div>
  
  <div className='icons-download-poliza'>
    <a href={link} target="_blank" rel="noopener noreferrer" download={link}> 
      <Icon icon="gg:software-download" className='icon-on-grey' />
      </a>
  </div>
  </div>
  </div>
  )
}

export default PolizaDigitalLinks