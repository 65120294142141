import React, {useEffect} from 'react'
import prensaData from '../utils/Texts/prensaData';
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom';
import PrensaDetailMobile from './PrensaDetailMobile';
import { MobileOnlyView, isMobileOnly} from 'react-device-detect';

const PrensaDetail = () => {
    useEffect(()=>{
        window.scrollTo(0,0);
      },[])

    const {id} = useParams()
    const note=prensaData.find(p=> p.id=== parseInt(id))
  return (
    <>
   { !isMobileOnly && 
   <div className='press-background'>
    <div className='product-content'>
      <div className='press-box-detail'>
          <div className='main-note-img'>
              <img src={note.img} alt={note.alt} />
              <div className='menu-note-detail'>
                    <Link to="/prensa"> <h5>ULTIMAS NOTICIAS</h5> </Link>
                </div>
                <div>
                    <Link to="/" className='press-menu-link'><h5>HOME</h5></Link>
                </div> 
          </div>
          <div className='press-note-box'>
              <div className='head-note'>
                  <h5>{note.media}</h5>
                  <h5 className='head-date'>{note.publicationDate}</h5>
              </div>
              <div className='main-note-content'>
                  <h2>{note.title}</h2>
                  <div className='box'>
                      <div className='box-text-detail'><p>{note.text}</p> <p>{note.text2}</p><p>{note.text3}</p><p>{note.text4}</p><p>{note.text5}</p><p>{note.text6}</p><p>{note.text7}</p><p>{note.text8}</p><p>{note.text9}</p><p>{note.foot}</p>
                      <p>{note.foot1}</p><p>{note.foot2}</p><p>{note.foot3}</p></div>
                      
                      </div>
                      <div >
                            <Link to="/prensa" className="more">
                            <div className='see'>Volver</div>
                            </Link>
                    </div>
                </div>
                </div>
                <div>
                </div>
            </div>
          
        </div>
        <div className="push"></div>
    </div>}
    <MobileOnlyView>
        <PrensaDetailMobile note={note} />
    </MobileOnlyView>
    </>
  )
}

export default PrensaDetail