import React from 'react'
import { Link } from 'react-router-dom'

const PressCardMobile = ({prensaData,id}) => {
  return (
    <div className='card-note-mobile'> 
                <div className='head-note'>
                    <h5>{prensaData.media}</h5>
                    <h5 className='head-date'>{prensaData.publicationDate}</h5>
                </div>
                <div className='card-note-content'>
                    <h3>{prensaData.title}</h3>
                    <p className='card-text'>{prensaData.text.substring(0, 80)}...</p>
                    <Link to={`/prensa-detail/${id}`} className="see-card">
                    Ver más</Link>
                    <img src={prensaData.img} alt={prensaData.alt} className="card-note-img" />
                </div>
            </div>   
  )
}

export default PressCardMobile