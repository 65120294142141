import React from 'react'
import "./footer.css"
import { NavLink } from 'react-router-dom'
import SocialIcons from './SocialIcons';
import LegalFooter from '../LegalFooter/LegalFooter';
import { MobileOnlyView, isMobileOnly } from 'react-device-detect';
import { useLocation } from 'react-router-dom'
import { companyData } from '../utils/Texts/companyData';
import footerTexts from '.././utils/Texts/footerTexts.json'
import { useLangContext } from '../../Context/LangContext'


const Footer = () => {
const location= useLocation()
const {selectedLanguage} = useLangContext()
const text = footerTexts[selectedLanguage];
    
  return (
    <>
  
    <div className= { isMobileOnly ? "display-none" :'footerBox'}>
        <div className='footer-in'>
        <img src="/img/LogoAFZw.png" alt="Logo Afz" />
        <div className='footerMenu'>
            {text.map((item, key)=>
            <div className='footerItem' key={key}>
                <NavLink to={item.link}>
                <h4 className='footerCategory'>{item.name}</h4>
                </NavLink>
            </div>
            )}
            <div className='footerItem'>
                <NavLink to="/">
                <h4 className='footerCategory'>HOME</h4>
                </NavLink>
            </div>
        </div>
        <div className='contactBox'>
        <p>{companyData.buenosAires.phone}</p>
      
        <p className='footerEmail'>{companyData.buenosAires.mail}</p>
        <SocialIcons className="social-box" classCircle="icon-circle" classIcon="social-icon" classIconBig="social-icon-big"/>
        </div>
        </div>    
        <LegalFooter />
    </div>
   
    <MobileOnlyView>
    <div className='footerBox'>
        <div className='logo-menu-box-mobile'> 
        <div className='logo-box-mobile'> <img src="/img/LogoAFZw.png" alt="Logo Afz" /></div>
        <div className='menu-footer-box'>
      
        <div className='footer-item-mobile'>
        <NavLink to="/prensa">
        <h4 className='footer-category-mobile'>PRENSA</h4>
        </NavLink>
        </div>
        <div className='footer-item-mobile'>
        <NavLink to="/contacto">
        <h4 className='footer-category-mobile'>CONTACTO</h4>
        </NavLink>
        </div>
        <div className='footer-item-mobile'>
        <NavLink to="/">
        <h4 className='footer-category-mobile'>HOME</h4>
        </NavLink>
        </div>
        </div>
        </div>
        <SocialIcons className="social-box-mobile" classCircle="icon-circle" classIcon="social-icon" classIconBig="social-icon-big"/>
        </div>


        {location.pathname === '/'?
        <LegalFooter />
        : null}
    </MobileOnlyView>
    </>
  )
}

export default Footer