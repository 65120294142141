
import BigButton from '../utils/BigButton/BigButton'
import { isMobile } from 'react-device-detect'

const currency=[
  "$", "u$s", "€"
]

const CotizadorForm = ({handleChangeRentData, handleSubmitRentData, rentData, errors, formTexts}) => {
  
  return (
    <div>
    <form className='rent-form' onSubmit={handleSubmitRentData}>
    <p className='input-explain'>{formTexts.explain}</p>
      <p className='required-explain'>{formTexts.required}</p>
    <label className='rent-label'>{formTexts.currency} *</label>
    <div className='currency-checkbox-box'>
    {currency.map((currencyType, key) => (
     
        <div className='currency-checkbox'>
          <p className='currency'>{currencyType}</p> 
        <input key={key} name='currency' value={currencyType} className='check' type="radio" onChange={e => handleChangeRentData(e, 'currency', 'currency', true, 3, 1)} required={true}/> 
        
      </div>
    ))}
    </div>
    
    <label className='rent-label'>{formTexts.leaseLabel} *</label>
    <div className='input-box'>
    <i className='i'>{rentData.currency}</i>
        <input name="rent" className="light-input-rent" value={rentData.rent} type="number" onChange={e => handleChangeRentData(e, 'rent', 'number', true, 15, 3)} required={true} />
        {errors['rent'] && <p className='error-white'>{errors['rent']}</p>}
    </div>
    <label className='rent-label'>{formTexts.expensesLabel}</label>
    <div className='input-box'>
    <i className='i'>{rentData.currency}</i>
        <input name="expenses" value={rentData.expenses} className='light-input-rent' type="number" onChange={e => handleChangeRentData(e, 'expenses', 'number', true, 15, 3)} required={false}  /> 
        {errors['expenses'] && <p className='error-white'>{errors['expenses']}</p>}
    </div>
    <label className='rent-label'>{formTexts.servicesLabel}</label>
    <div className='input-box'>
    <i className='i'>{rentData.currency}</i>
        <input name="services" value={rentData.services} className='light-input-rent' type="number" onChange={e => handleChangeRentData(e, 'services', 'number', true, 15, 3)} required={false}  /> 
        {errors['services'] && <p className='error-white'>{errors['services']}</p>}
    </div>
    <label className='rent-label'>{formTexts.depositLabel}</label>
    <div className='input-box'>
    <i className='i'>{rentData.currency}</i>
        <input name="deposit" value={rentData.deposit} className='light-input-rent' type="number" onChange={e => handleChangeRentData(e, 'deposit', 'number', true, 15, 3)} required={false}  /> 
        {errors['deposit'] && <p className='error-white'>{errors['deposit']}</p>}
    </div>

    <label className='rent-label'>{formTexts.timeLabel} *</label>
    <div className='period-checkbox'>
    {formTexts.periods.map((period, key) => (
     
        <div className='currency-checkbox'>
        <p className='period-text'>{period}</p>
        <input key={key} name='period' value={period} className='check' type="radio" onChange={e => handleChangeRentData(e, 'period', 'number', true, 3, 1)} required={true}/> 
        
      </div>
    ))}
    </div>
    <div className='rent-send'>
        <BigButton type="submit" name= {formTexts.quoteButton} className={isMobile ? "contact-dark-button":"rent-button"}/>
        </div>
  </form>
   </div>
  )
}

export default CotizadorForm