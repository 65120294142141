import React from "react";
import { Link } from "react-router-dom";

const PrensaDetailMobile = ({ note }) => {
  return (
    <div className="press-background-mobile">
      <div className="mobile-content">
        <div className="head-note-mobile">
          <h5>
            {note.media}
          </h5>
          <h5 className="head-date">
            {note.publicationDate}
          </h5>
        </div>
        <h2 className="title-press-mobile">
          {note.title}
        </h2>
        <div className="main-note-img">
          <img src={note.img} alt={note.alt} />
        </div>
        <div>
          <div className="box-text-mobile-detail">
            <p>{note.text}</p> 
            <p>{note.text2}</p>
            <p>{note.text3}</p>
            <p>{note.text4}</p>
            <p>{note.text5}</p>
            <p>{note.text6}</p>
            <p>{note.text7}</p>
            <p>{note.foot}</p>
            <p>{note.foot1}</p>
          </div>
        </div>
        <div>
          <Link to="/prensa" className="more">
            <div className="see">Volver</div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PrensaDetailMobile;
