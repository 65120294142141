import React from 'react'
import DescargarItemComplex from '../utils/DescargarItem/DescargarItemComplex'
import ReactPlayer from 'react-player'
import PolizaDigitalLinks from './PolizaDigitalLinks'


const FirstPolizaComp = ({selectedLanguage, firstText, title}) => {
    
  return (
    <>
    <h6 className={selectedLanguage === "EN" ? 'display-none':'digital-subtitle'}>¿Qué es una Póliza de Caución con Firma Digital?</h6>
    <p className='digital-text'>{firstText}</p>
    <h6 className={selectedLanguage === "EN" ? 'display-none':'digital-subtitle'}>¿Cómo visualizar las pólizas con Legalización y Certificación?</h6>
    <p className={selectedLanguage === "EN" ? 'display-none':'digital-text'}>A continuación, un breve instructivo en el cual mostramos 4 simples pasos para acceder a nuestras pólizas Legalizadas o Certificadas.</p>
    <div className={selectedLanguage === "EN" ? 'display-none':'vimeo-box'}>
    <ReactPlayer
    url="https://www.youtube.com/watch?v=XCcexLp19t4"
    controls="true"
    playsinline="true"
    width="100%"
    className={selectedLanguage === "EN" ? 'display-none':'video-size'}
    />
    </div>
   
    <h6 className={selectedLanguage === "EN" ? 'display-none':'digital-subtitle'}>¿Cómo verificar la validez de una firma digital?</h6>
    
    <div className={selectedLanguage ==="EN" && "display-none"}>
    <div className='first'>
    <h6>POR ÚNICA VEZ</h6>
    <DescargarItemComplex title="Descargar e Instalar el Adobe Acrobat Reader DC" fileDownload="https://get.adobe.com/reader/?loc=es" />
    </div>
    <div className='vimeo-box'>
    <ReactPlayer
    url="https://www.youtube.com/watch?v=fhK2dO44WdI"
    controls="true"
    playsinline="true"
    width="100%"
    className={selectedLanguage === "EN" ? 'display-none':'video-size'}
    />
    </div>
    <div className='two-column'>
    <div className='first'>
      <h6>DESCARGAR E INSTALAR LOS CERTIFICADOS NECESARIOS PARA HACER LA VALIDACIÓN:</h6>
      <PolizaDigitalLinks caracter={"A"} title='El Certificado Raiz de Argentina' link="https://acraiz.gov.ar/acraizra.crt"  linkName="https://acraiz.gov.ar/acraizra.crt" />
      <PolizaDigitalLinks caracter={"B"} title="El Certificado de la Autoridad Certificante de nuestra Firma Digital" link="https://www.encodesa.com.ar/firma-digital/encode.crt" linkName="https://www.encodesa.com.ar/firma-digital/encode.crt"/>
      <PolizaDigitalLinks caracter={"C"} title="El Certificado de Afianzadora Latinoamericana" link="/Certificados/EMP-20227065908-307091879411164766.crt" linkName="Descargar el certificado" />
    </div>
   {/*  <div className='second'>
      <h6>SI LA PÓLIZA ES CERTIFICADA:</h6>
      <PolizaDigitalLinks caracter={"D"} title='El Certificado de la Escribanía' link="http://www.afianzadora.com.ar/Certif_Escribania.cer" />
      <PolizaDigitalLinks caracter={"E"} title='El Certificado del Colegio de Escribanos de CABA' link="http://www.afianzadora.com.ar/Certif_Colegio_de_Escribanos.cer" />
      <p className='digital-foot'>(En caso de duda sobre cuál debés instalar, podés instalarlos todos y asegurarte que tu póliza valide en todos los casos)</p>
    </div> */}
    </div>
  <div className='vimeo-box'>
    <ReactPlayer
    url="https://www.youtube.com/watch?v=PEfrDlmvxik"
    controls
    width="100%"
    className='video-size'
    />
    </div>
    <div className='digital-block'>
    <h6>CADA VEZ QUE NECESITES VERIFICAR UNA PÓLIZA DE AFIANZADORA:</h6>
    <p className='digital-text'>Abrir el PDF con tu Acrobat Reader y consultar el panel de firma digital para verificar su validez y acceder a los detalles de la misma.</p>
    </div>
    <div className='vimeo-box'>
    <ReactPlayer
    url="https://www.youtube.com/watch?v=iKbw69UMfUg"
    controls
    width="100%"
    className={selectedLanguage === "EN" ? 'display-none':'video-size'}
    />
    </div>
    </div>
  
</>


  )
}

export default FirstPolizaComp